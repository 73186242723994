import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <div><a href='https://discord.com/api/oauth2/authorize?client_id=557650964887240724&permissions=2112&scope=bot%20applications.commands'><img alt='mightybot logo' src='public/logo.png'></img>Install Bot</a></div>
        <div><a href='privacy-policy'>Privacy Policy</a></div>
        <div><a href='terms'>Terms of Service</a></div>        
    </div>
  );
}

export default App;
